.ratingTable {
  table-layout: fixed;
  width: 100%;
  tr th {
    width: 33%;
  }
  tr td {
    width: 33%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0;
  }
}

.area {
  margin-bottom: 15px;
}
.areaTitle {
  font-size: 16px;
  margin-bottom: 5px;
}
.areaContent {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}
