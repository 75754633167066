.headerBottom {
  height: auto !important;
}

.menuItem {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.menuItemActive {
  background-color: #f3f6f9 !important;
  span {
    color: #3699ff !important;
  }
}

.logo {
  max-width: 200px;
}
