.pagesWrapper {
  flex-direction: row;

  flex-wrap: wrap;
  width: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
}
.page {
  width: 210px;
  height: 297px;
  border: 1px solid #dfdfdf;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  padding: 20px;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 15px;
  position: relative;
}

.pagePageIndicator {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.pageDelete {
  top: 10px;
  right: 10px;
  position: absolute;
  border: none;
  background: none;
}
.addPage {
  border: 1px dashed #b0b0b0;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    opacity: 0.5;
  }
}
