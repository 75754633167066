.fileRow {
  background-color: var(--bs-table-bg);

  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 9999px inset;
  width: 100%;
  display: table-row;
  //   flex-direction: row;
  //   display: flex;
  //   align-items: center;
  & > div {
    transition: all 0.12s;
  }
  &:hover > div {
    background-color: rgb(54 153 255 / 3%);
  }
}

.files {
  display: table;
}
.fileIconWrapper {
  padding-right: 14px;
  top: -3px;
  position: relative;
}
.fileIcon {
}
.fileIconIcon {
}
.fileTitle {
  color: rgb(37, 47, 74);
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}

.fileTitleLink {
  color: rgb(37, 47, 74);
}
.fileRow:hover .fileActionsWrapper {
  opacity: 1;
}
.fileActionsWrapper {
  opacity: 0;
}
.fileActionsWrapper {
  display: flex;
  align-items: center;
}

.files {
  display: grid;
  grid-template-columns: auto 1fr auto; /* Three columns: icon, title, actions */
  gap: 0px;
}

.fileRow {
  display: contents; /* This allows the children to act like direct children of the grid */
}

.fileIcon {
  grid-column: 1; /* Position the icon in the first column */
}

.fileTitle {
  grid-column: 2; /* Position the title in the second column */
}
.fileIcon,
.fileTitle,
.fileActions,
.fileCreatedAt,
.fileSize,
.fileCreator {
  // display: table-cell;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgb(241, 241, 244);
}

.fileTitle.fullSpan {
  grid-column: 1 / span 2; /* Expand the title from the first to the second column */
}

.fileSize {
  grid-column: 3; /* Position the actions in the third column */
  color: gray;
  font-size: 12px;
  padding: 1.25rem 15px;
}
.fileCreator {
  color: gray;
  font-size: 12px;
  grid-column: 4;
  padding: 1.25rem 15px;
}
.fileCreatedAt {
  color: gray;
  font-size: 12px;
  grid-column: 5;
  padding: 1.25rem 15px;
}
.fileActions {
  padding-left: 15px;
  grid-column: 6; /* Position the actions in the third column */
}

.breadCrumbs {
  background-color: #e9f3ff;
  color: #1b84ff;
  border-radius: 7px;
  padding: 4px 6px;
  width: auto;
  display: flex;
  align-items: center;
}
.breadCrumbsItem {
}
.breadCrumbArrow {
  margin: 0 4px;
}

.emptyFiles {
  min-height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emptyFilesIcon {
  opacity: 0.5;
}

.emptyFilesTitle {
  font-size: 18px;
  margin-top: 20px;
}
.emptyFilesUploadTitleContent {
}
.emptyFilesUploadTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: gray;
  margin-top: 20px;
}
.emptyFilesUpladIcon {
  margin-right: 20px;
  opacity: 0.5;
}

.dropzone {
}

.dropzone {
  transition: border-color 0.3s ease-in-out;
  border: 2px dashed transparent;
}

.dropzoneHover {
  border: 2px dashed #ccc;
  border-color: #007bff; /* Niebieski kolor obramowania */
}
