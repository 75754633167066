.period {
  width: 200px;
}
.details {
  width: 200px;
}

.title {
  text-transform: capitalize;
  color: black;
  background-color: rgb(73, 182, 255);
  color: white;
  padding: 2px 5px 2px 10px;
  font-size:14px;
}

.tr td {
  &:first-child {
    min-width: 300px;
  }
  padding: 2px 10px !important;
}

.table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
