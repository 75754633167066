.left {
  // background-image: url('https://reviso-wordpress-sites.s3.amazonaws.com/uploads/sites/2/inventory-management-blog.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  flex: 1;
  position: relative;
}
.leftOverlay {
  width: 100%;
  position: absolute;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 50px;
  display: flex;
  align-items: flex-end;
  h1 {
    font-size: 45px;
  }
  h1,
  p {
    font-family: Open Sans;
    font-weight: 300;
  }
}
.title {
  font-size: 45px;
  font-weight:100 !important;
}
.slogan {
  font-size:25px;
  font-weight: 100 !important;
}

.right {
  background-color: white;
  width: 100%;
  max-width: 350px;
  padding: 40px;
}

.divider {
  height: 1px;
  margin: 30px 0;
  width: 100%;
  background-color: #ebebeb;
}
.buttons {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 15px;
  }
}
