.wrapper {
  position: relative;
  width:100%;
  min-height: 100px;
}
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  border-radius:4px;
}
.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
