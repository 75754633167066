.checkboxList {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
}
.checkbox {
  margin-bottom: 15px;
}

.columnsButton:after {
  display: none !important;
}

.search {
  display: flex;
  align-items: center;
}
.searchInput {
  margin-left: 20px;
  margin-bottom: 0;
}
